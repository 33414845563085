import { DefaultBtn } from 'Ncontracts.Common.UI';
import { EmptyState } from 'NstyleComponents';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { transfer } from '../../actions/user/userAction';
import { imageCdnUrls } from '../common/ImageCdnUrls';
import "./_style.scss";

const ErrorPage = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const handleLogout = () => {
        history.push("/auth/login");
    };

    const handleTransferToHub = () => {
        dispatch(transfer("Hub"))
            .then((result) => {
                window.location.replace(result.transferUrl);
            })
            .catch((error) => {
                console.error('Error initiating transfer to Hub:', error);
            });
    };

    const buttons = (
        <div>
            <div className="error-page__buttons-wrapper">
                <DefaultBtn className={"error-page__button"}
                            onClick={handleLogout}
                            data-testid="error_page_logout_button">
                    Logout
                </DefaultBtn>
                <DefaultBtn className={"error-page__button"}
                            onClick={handleTransferToHub}
                            data-testid="error_page_transfer_to_hub_button">
                    Redirect to Hub
                </DefaultBtn>
            </div>
        </div>
    );

    return (
        <EmptyState
            className={"error-page"}
            image={imageCdnUrls.coffeeSpill}
            primaryMessage={"Oops. Something went wrong."}
            secondaryMessage={"Please choose from one of the following options:"}
            button={buttons}
        />
    );
};

export default ErrorPage;
