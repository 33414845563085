import React from "react";
import PropTypes from "prop-types";
import {imageCdnUrls} from "../ImageCdnUrls";

export default function SupportNav({transferToProduct}) {
    return (
        <div onClick={() => transferToProduct("Help")}>
            <div className="top-rail-icon-container has-tooltip-bottom" data-tooltip="Support"
                 data-testid="login_support_button">
                    <img src={imageCdnUrls.helpSupport} alt="support"/>
                <div className="hovered-nav-icon-background"/>
            </div>
        </div>
    );
}

SupportNav.propTypes = {
    transferToProduct: PropTypes.func
};
