import { PrimaryHeader, SolidBtn, TertiaryHeader, toast } from 'NstyleComponents';
import React, { MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import { ReactSVG } from 'react-svg';
import * as actions from '../../../actions/auth/forgotPasswordAction';
import { imageCdnUrls } from '../../../components/common/ImageCdnUrls';
import { DocTitle } from '../../common/DocTitle';
import './_style.scss';

interface AccountLockedPageProps {
    match: {
        params: {
            email: string;
        };
    };
    referrer: any;
}


const AccountLockedPage = ({ match, referrer }: AccountLockedPageProps) => {

    const email = match.params.email;

    const dispatch = useDispatch();

    const requestPasswordRecovery = (event: MouseEvent) => {
        event.preventDefault();

        dispatch(actions.requestPassword(email, referrer.platform));
        toast.success("Sent! Check your email for a link to reset your password. If it doesn't appear within a few minutes, check your spam folder.");
    };

    return (
        <div>
            <DocTitle syncTo="Password Recovery Assistance" />
            <div style={{ background: `url(${imageCdnUrls.doubleBlobBackground}) no-repeat center / cover` }} className={"account-locked-page"}>
                <div className={"account-locked-page__form"}>
                    <div className={"account-locked-page__form-header"}>
                        <ReactSVG
                            src={imageCdnUrls.nLogoDarkGrey}
                            className={"account-locked-page__form-header-logo"}
                        />
                        <PrimaryHeader className={"account-locked-page__form-header-title"}>Account Locked</PrimaryHeader>
                        <TertiaryHeader>It looks like you need to reset your password.</TertiaryHeader>
                        <TertiaryHeader className={"account-locked-page__form-header-subtitle"}>Would you like to send a password reset link to <br /> {email}?</TertiaryHeader>
                    </div>
                    <div className={"account-locked-page__form-body"}>
                        <SolidBtn
                            className={"account-locked-page__recovery-email-btn"}
                            onClick={requestPasswordRecovery}
                            data-testid="request_password_recovery_button"
                        >
                            Send recovery email
                        </SolidBtn>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountLockedPage;
