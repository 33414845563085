import { BaseBtn, BaseNoPaddingBtn, PrimaryHeader, PrimaryLabel, SingleLineInput } from 'NstyleComponents';
import React, { useEffect, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import * as actions from '../../../actions/auth/resetPasswordAction';
import { clearStatus } from '../../../actions/auth/signInAction';
import { imageCdnUrls } from '../../../components/common/ImageCdnUrls';
import { DocTitle } from '../../common/DocTitle';
import PasswordStrengthMeter from './passwordStrength/PasswordStrengthMeter';
import './_style.scss';
import PasswordValidator from './passwordStrength/PasswordValidator';
import { useHistory } from 'react-router-dom';
//@ts-ignore
import qs from 'query-string';

interface ResetPasswordPageProps {
    match: {
        params: {
            nonce: string;
        };
    };
}

const ResetPasswordPage: React.FC<ResetPasswordPageProps> = ({ match }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const status = useSelector((state: any) => state.resetPassword.status);
    const errors = useSelector((state: any) => state.resetPassword.errors);
    const [isValid, setIsValid] = useState<boolean>(true);
    const [validationMessage, setValidationMessage] = useState<string[]>([]);

    const [newPassword, setNewPassword] = useState('');
    const [newPasswordRepeat, setNewPasswordRepeat] = useState('');
    const [passwordChecks, setPasswordChecks] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
        specialChar: false
    });

    const successColor = '#1E8900';
    const defaultColor = 'black';

    const [passwordMatch, setPasswordMatch] = useState<boolean>(false);

    const [showPassword, setShowPassword] = useState(false);
    const [inputType, setInputType] = useState<string>("password");

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const [expired, setExpired] = useState<boolean | null>(null);

    const resetPassword = () => {

        if (validate()) {
            dispatch(actions.resetPassword(match.params.nonce, newPassword));
        }
    };

    const handleKeyPressed = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            resetPassword();
        }
    };


    const validate = () => {
        let validationPassed = true;
        let messages: string[] = [];

        if (newPassword.trim().length === 0) {
            messages.push("Please enter your new password.");
            validationPassed = false;
        } else if (newPasswordRepeat.trim().length === 0) {
            messages.push("Please confirm your new password.");
            validationPassed = false;
        } else if (newPassword !== newPasswordRepeat) {
            messages.push("Passwords do not match. Please make sure you enter the same password.");
            validationPassed = false;
        }

        setIsValid(validationPassed);
        setValidationMessage(messages);

        return validationPassed;
    };

    const handleNewPasswordChanged = () => {
        setPasswordChecks({
            length: PasswordValidator.isLengthValid(newPassword),
            uppercase: PasswordValidator.hasUpperCase(newPassword),
            lowercase: PasswordValidator.hasLowerCase(newPassword),
            number: PasswordValidator.hasNumber(newPassword),
            specialChar: PasswordValidator.hasSpecialCharacter(newPassword)
        });

        const passwordsMatch = newPassword === newPasswordRepeat;
        setPasswordMatch(newPassword.length > 0 && newPasswordRepeat.length > 0 && passwordsMatch);
    };

    const handleErrors = (err: number) => {
        switch (err) {
            case 1:
            case 2:
                return (
                    <span>
                        This link has expired.
                        <a href="/auth/sendPasswordResetLink"> Click here to request another password reset email.</a>
                    </span>
                );
            case 3: return "Unable to reset this password. Please contact our customer support team.";
            case 4: return "Password must be different from previous password.";
            case 5: return "Password must contain at least 8 characters.";
            case 6: return "Password must contain at least one lowercase letter.";
            case 7: return "Password must contain at least one uppercase letter.";
            case 8: return "Password must contain at least one number.";
            case 9: return "Password must contain at least one special character.";
            default: return String(err);
        }
    };


    useEffect(() => {
        const queryString = qs.parse(window.location.search);
        setExpired(queryString.e === '1' ? true : false);
    }, []);

    useEffect(() => {
        handleNewPasswordChanged();
        setIsValid(true);

    }, [newPassword, newPasswordRepeat]);

    useEffect(() => {
        setInputType(showPassword ? "text" : "password");
    }, [showPassword]);

    useEffect(() => {
        if (errors.length > 0) {
            setValidationMessage(errors.map((err: number) => handleErrors(err)).flat());
            setIsValid(false);
        } else {
            setValidationMessage([]);
            setIsValid(true);
            if (status === 'PasswordResetSuccess') {
                dispatch(clearStatus());
                history.push("/auth");
            }
        }
    }, [errors]);

    return (
        <div>
            <DocTitle syncTo="Reset Your Password" />
            <div style={{ background: `url(${imageCdnUrls.doubleBlobBackground}) no-repeat center / cover` }} className={"resetPW-page"}>
                <div className={"resetPW-page__form"}>
                    <div className={"resetPW-page__form-header"}>
                        <BaseNoPaddingBtn
                            className={"resetPW-page__form-header-back-btn"}
                            data-testid="password_reset_back_button"
                            onClick={() => history.push("/auth")}>
                            {'< Cancel and return to sign in page'}
                        </BaseNoPaddingBtn>
                        <ReactSVG
                            src={imageCdnUrls.nLogoDarkGrey}
                            className={"resetPW-page__form-header-logo"}
                        />
                        <PrimaryHeader className={"resetPW-page__form-header-title"}>
                            {expired
                                ? "Password Expired"
                                : "Reset Password"
                            }
                        </PrimaryHeader>
                    </div>
                    <div className={"resetPW-page__form-body"}>
                        <div className={"resetPW-page__form-field"}>
                            <PrimaryLabel className={"resetPW-page__form-field-label"}>Enter new password</PrimaryLabel>
                            <div className={"resetPW-page__form-field-wrapper"}>
                                <SingleLineInput
                                    onChange={(password) => setNewPassword(password)}
                                    value={newPassword}
                                    placeholder={""}
                                    isValid={isValid}
                                    onKeyPress={(e) => handleKeyPressed(e)}
                                    {...{ autoFocus: true }}
                                    className={"resetPW-page__form-field-input"}
                                    {...{ type: inputType }}
                                    data-testid="reset_password_input"
                                />
                                {showPassword ? (
                                    <FaEyeSlash onClick={togglePasswordVisibility} className="eye-icon" />
                                ) : (
                                    <FaEye onClick={togglePasswordVisibility} className="eye-icon" />
                                )}
                            </div>
                            {newPassword.length > 0 &&
                                <PasswordStrengthMeter password={newPassword} />
                            }
                            <div className="pw-strength-checklist">
                                <div style={{ color: passwordChecks.length ? successColor : defaultColor }}>
                                    {passwordChecks.length ? '\u2714 ' : ''}Must be at least 8 characters
                                </div>
                                <div style={{ color: passwordChecks.uppercase ? successColor : defaultColor }}>
                                    {passwordChecks.uppercase ? '\u2714 ' : ''}1 Uppercase Letter (A-Z)
                                </div>
                                <div style={{ color: passwordChecks.lowercase ? successColor : defaultColor }}>
                                    {passwordChecks.lowercase ? '\u2714 ' : ''}1 Lowercase Letter (a-z)
                                </div>
                                <div style={{ color: passwordChecks.number ? successColor : defaultColor }}>
                                    {passwordChecks.number ? '\u2714 ' : ''}1 Number (0-9)
                                </div>
                                <div style={{ color: passwordChecks.specialChar ? successColor : defaultColor }}>
                                    {passwordChecks.specialChar ? '\u2714 ' : ''}1 Special character (!@#$%^&*)
                                </div>
                            </div>

                            <PrimaryLabel className={"resetPW-page__form-field-label"}>Re-type new password</PrimaryLabel>
                            <div className={"resetPW-page__form-field-wrapper"}>
                                <SingleLineInput
                                    onChange={(newPasswordRepeat) => setNewPasswordRepeat(newPasswordRepeat)}
                                    value={newPasswordRepeat}
                                    placeholder={""}
                                    isValid={isValid}
                                    {...{ type: inputType }}
                                    onKeyPress={(e) => handleKeyPressed(e)}
                                    className={"resetPW-page__form-field-input"}
                                    data-testid="reset_password_retype_input"/>
                                {showPassword ? (
                                    <FaEyeSlash onClick={togglePasswordVisibility} className="eye-icon" />
                                ) : (
                                    <FaEye onClick={togglePasswordVisibility} className="eye-icon" />
                                )}
                            </div>
                            <div className={"resetPW-page_must-match"} style={{ color: passwordMatch ? successColor : defaultColor }}>
                                {passwordMatch ? '\u2714 ' : ''} Must match
                            </div>
                            <BaseBtn
                                className={"resetPW-page__reset-password-btn"}
                                onClick={resetPassword}
                                data-testid="reset_password_submit_button">
                                Reset my password
                            </BaseBtn>
                            {!isValid &&
                                <div className={"resetPW-page__error"}>
                                    {validationMessage.map((message, index) => (
                                        <div key={index}>{message}</div>
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPasswordPage;
