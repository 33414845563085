import { BaseBtn, BaseNoPaddingBtn, Checkbox, PrimaryHeader, PrimaryLabel, SingleLineInput } from 'NstyleComponents';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clearStatus, confirmOneTimePassword, resendOneTimePassword } from '../../../actions/auth/signInAction';
import { Modality, ModalityType } from '../../../contracts/types/models/Modality';
import { DocTitle } from '../../common/DocTitle';
import './_style.scss';
import { imageCdnUrls } from '../../../components/common/ImageCdnUrls';
import { ReactSVG } from 'react-svg';

interface OtpConfirmationProps {
    referrer: any;
}

const OtpConfirmation: React.FC<OtpConfirmationProps> = ({ referrer }) => {
    const [code, setCode] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [rememberDevice, setRememberDevice] = useState<boolean>(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const status: string = useSelector((state: any) => state.auth.status);
    const nonce: string = useSelector((state: any) => state.auth.nonce);
    const twoFactorMethods: Modality[] = useSelector((state: any) => state.auth.modalities);
    const [selectedModality, setSelectedModality] = useState<ModalityType | null>(null);

    useEffect(() => {
        if (nonce.length === 0) {
            history.push('/auth/login');
        }

        const storedModality = sessionStorage.getItem('selectedModality');
        if (storedModality) {
            setSelectedModality(storedModality.toLowerCase() as ModalityType);
        }
    }, []);

    useEffect(() => {
        if (status === 'OtpFail') {
            setError('Incorrect temporary code. Please try again.');
        }
    }, [status]);

    const validate = () => {
        if (code.trim().length === 0) {
            setError('Please enter temporary code.');
            return false;
        }
        return true;
    };

    const handleCodeChanged = (code: string) => {
        setCode(code);
        setError("");
    };

    const handleRememberDeviceChanged = () => {
        setRememberDevice(!rememberDevice);
    };

    const handleKeyPressed = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            confirmOtp();
        }
    };

    const confirmOtp = () => {
        if (validate()) {
            dispatch(clearStatus());
            dispatch(confirmOneTimePassword(nonce, code, referrer.platform, referrer.platformRequestedRedirectUrl, referrer.isSSO, rememberDevice));
        }
    };

    const resendOtp = () => {
        dispatch(resendOneTimePassword(nonce, twoFactorMethods));
        history.push('/auth/requestIdentificationMethods');
    };

    return (
        <div>
            <DocTitle syncTo="Select how you want to receive your temporary code" />
            <div style={{ background: `url(${imageCdnUrls.doubleBlobBackground}) no-repeat center / cover` }} className={"otp-page"}>
                <div className={"otp-page__form"}>
                    <div className={"otp-page__form-header"}>
                        <BaseNoPaddingBtn
                            className={"otp-page__form-header-back-btn"}
                            data-testid="otp_confirmation_back_button"
                            onClick={resendOtp}>
                            {'< Back'}
                        </BaseNoPaddingBtn>
                        <ReactSVG
                            src={imageCdnUrls.nLogoDarkGrey}
                            className={"otp-page__form-header-logo"}
                        />
                        <PrimaryHeader className={"otp-page__form-header-title"}>
                            A code has been <br/> sent to your {selectedModality}
                        </PrimaryHeader>
                    </div>
                    <div className={"otp-page__form-body"}>
                        <div className={"otp-page__form-field"}>
                            <PrimaryLabel className={"otp-page__form-field-label"}>Enter the code</PrimaryLabel>
                            <SingleLineInput
                                onChange={(code) => handleCodeChanged(code)}
                                value={code}
                                placeholder={""}
                                isValid={error.length === 0}
                                onKeyPress={e => handleKeyPressed(e)}
                                {...{ autoFocus: true }}
                                className={"otp-page__form-field-input"}
                                data-testid="otp_confirmation_code_input"
                            />
                            {error.length > 0 &&
                                <div className={"otp-page__error"}>
                                    {error}
                                </div>
                            }
                        </div>
                        <BaseBtn
                            className={"otp-page__confirm-code-btn"}
                            onClick={confirmOtp}
                            data-testid="otp_confirmation_submit_button">
                            Confirm
                        </BaseBtn>
                    </div>
                    <Checkbox
                        isChecked={rememberDevice}
                        onChange={handleRememberDeviceChanged}
                        className={"otp-page__remember-me-chk-box"}
                        data-testid="otp_confirmation_remember_checkbox">
                        Remember this device?
                    </Checkbox>
                    <div className={"otp-page__form-footer"}>
                        Didn't receive a code?
                        <div className={"otp-page__form-footer-links"}>
                            <BaseNoPaddingBtn
                                onClick={resendOtp}
                                className={"otp-page__form-footer-link"}
                                data-testid="otp_confirmation_resend_button">
                                Resend
                            </BaseNoPaddingBtn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OtpConfirmation;
