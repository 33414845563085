import PropTypes from "prop-types";
import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {setTopRailNavigation} from "../../../actions/nav/topRailNavAction";
import {CurrentUser} from "../../../services/currentUser";
import _ from "lodash";
import {logout} from "../../../actions/auth/signInAction";

class UserNav extends Component {

    static propTypes = {
        isActive: PropTypes.bool,
        setSelectedTopRailNav: PropTypes.func,
        user: PropTypes.object,
        history: PropTypes.object,
        logout: PropTypes.func
    };

    static defaultProps = {
        isActive: undefined
    };

    componentDidMount() {
        window.addEventListener("click", this.closeUserMenuIfOpen);
    }

    componentWillUnmount() {
        window.removeEventListener("click", null);
    }

    closeUserMenuIfOpen = (e) => {
        if (this.props.isActive) {
            const userClickedOutsideOfMenu = !(_.find(e.path, (p) => {
                return p.className && p.className.includes('user-menu');
            }));
            if (userClickedOutsideOfMenu) {
                this.toggleUserMenu(e);
            }
        }
    };

    toggleUserMenu = (e) => {
        e.stopPropagation();
        this.props.setSelectedTopRailNav(this.props.isActive ? "" : "User");
    };

    logoutAndNavigateToLoginPage = () => {
        this.props.logout().then(() => {
            this.props.history.push("/auth/login");
        }).error(() => {
            this.props.history.push("/auth/login");
        });
    };

    render() {
        const {isActive} = this.props;

        const userMenu = isActive
            ? <div className="top-nav-menu user-menu">
                <div className="arrow"/>
                <div className="menu-content">
                    <div className="user-name">{CurrentUser.fullName}</div>
                    <div className="customer-name">{CurrentUser.customerName}</div>
                    <div>
                        <ul>
                            <li>
                                <button onClick={this.logoutAndNavigateToLoginPage}
                                        data-testid="auth_logout_button">
                                    Logout
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            : undefined;

        const activeNavBackground = isActive
            ? <div className="active-nav-icon-background"/>
            : undefined;

        return (
            <div>
                <div className="top-rail-icon-container user-nav-container" onClick={this.toggleUserMenu}>
                    <div className="nav-img-container">
                        <div className="circle">
                            <div className="initials has-text-centered">
                                {CurrentUser.initials}
                            </div>
                        </div>
                    </div>
                    <div className="hovered-nav-icon-background"/>
                    {activeNavBackground}
                </div>
                {userMenu}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isActive: state.topRailNav.activeElementId === "User"
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setSelectedTopRailNav: (selectedElementId) => dispatch(setTopRailNavigation(selectedElementId)),
        logout: () => dispatch(logout())
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserNav));
