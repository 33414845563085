import React from "react";
import * as PropTypes from "prop-types";

export const ActiveProductTile = ({product, onClickHandler}) => {

    return (
        <div className="product-tile"
             onClick={() => onClickHandler(product)}
             data-testid={product.displayName}>
            <img src={product.icon} alt={product.displayName} />
        </div>
    );
};

ActiveProductTile.propTypes = {
    product: PropTypes.object.isRequired,
    onClickHandler: PropTypes.func.isRequired
};
